@import url(https://fonts.googleapis.com/css?family=Open+Sans&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App{text-align:center;display:flex;justify-content:center;align-items:center;font-family:"Open Sans", sans-serif}.App-logo{-webkit-animation:App-logo-spin infinite 20s linear;animation:App-logo-spin infinite 20s linear;height:40vmin;pointer-events:none}.App-header{background-color:#282c34;min-height:100vh;display:flex;flex-direction:column;align-items:center;justify-content:center;font-size:calc(10px + 2vmin);color:white}.App-link{color:#61dafb}@-webkit-keyframes App-logo-spin{from{-webkit-transform:rotate(0deg);transform:rotate(0deg)}to{-webkit-transform:rotate(360deg);transform:rotate(360deg)}}@keyframes App-logo-spin{from{-webkit-transform:rotate(0deg);transform:rotate(0deg)}to{-webkit-transform:rotate(360deg);transform:rotate(360deg)}}.login{width:27em;height:45em;display:flex;justify-content:center;align-items:center;margin-top:5px;position:relative;z-index:99}.login .container{display:flex;justify-content:center;align-items:center;background-color:#fff;box-shadow:0px 0px 12px 2px rgba(15,15,15,0.2);border-radius:4px;position:relative;z-index:99;width:100%;height:100%;z-index:99;padding:17px 10px}.main-btn{font-size:21px;padding:5px 20px;border:0;background-color:#3498db;color:#fff;border-radius:3px;transition:all 250ms ease-in-out;cursor:pointer}.main-btn:hover{background-color:#2386c8}.main-btn:focus{outline:none}.btn{font-size:21px;padding:5px 20px;border:0;background-color:#feffff;color:#3498da;border-radius:3px;transition:all 250ms ease-in-out;cursor:pointer}.reset-password-btn{font-size:16px;padding:0;border:0;background-color:#feffff;color:#883d55;border-radius:3px;transition:all 250ms ease-in-out;cursor:pointer}

.base-container{width:100%;display:flex;flex-direction:column;align-items:center}.base-container .header{font-size:24px;font-family:"Open Sans", sans-serif}.base-container .content{display:flex;flex-direction:column}.base-container .content .image{width:21em}.base-container .content .image img{width:40%;height:40%}.base-container .content .form{margin-top:0.2em;display:flex;flex-direction:column;align-items:center}.base-container .content .form .form-group{display:flex;flex-direction:column;align-items:flex-start;width:-webkit-fit-content;width:-moz-fit-content;width:fit-content}.base-container .content .form .form-group label{font-size:20px}.base-container .content .form .form-group input,.base-container .content .form .form-group select{margin-top:6px;min-width:18em;height:37px;padding:0px 10px;font-size:16px;font-family:"Open Sans", sans-serif;background-color:#f3f3f3;border:0;border-radius:4px;margin-bottom:31px;transition:all 250ms ease-in-out}.base-container .content .form .form-group input:focus,.base-container .content .form .form-group select:focus{outline:none;box-shadow:0px 0px 12px 0.8px #3474dbb2}.base-container .footer{margin-top:1.5em}.base-container .error{color:red;display:inline}

